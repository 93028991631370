export default {
	countries: {
		now: "Sie befinden sich in",
		change: "Ändern",
		name: {
			ES: "Spanien",
			FR: "Frankreich",
			DE: "Deutschland",
			IT: "Italien",
			UK: "Großbritannien",
			MX: "Mexiko",
			PT: "Portugal",
			EUR: "Europa",
			AME: "Amerika",
		},
	},
	global: {
		timeAgoMinutes: "vor {minutes} Min.",
		timeAgoHours: "vor {hours} Std.",
		timeAgoHoursMinutes: "vor {hours} Std. und {minutes} Min.",
		timeAgoDay: "vor 1 Tag",
		timeAgoDays: "vor {days} Tagen",
	},
	pages: {
		home: "Startseite",
		leaf: "Details",
		list: "Liste",
		contacts: "Kontakte",
	},
	components: {
		ProductDescription: {
			readMore: "Weiter lesen",
		},
		SliderCardFinder: {
			most_find: "Meistgesuchte:",
		},
	},
	product_types: {
		1: "Laufschuhe",
		45: "Sportuhren",
		71: "Lauftights",
		72: "Suppléments de sport",
		73: "Fitness-tracker",
		74: "Smartwatch",
		75: "Fahrradcomputer",
		76: "Kopfhörer",
		84: "Trekkingschuhe",
		102: "Sonnenbrille",
		105: "Fitnessgeräte",
	},
	sections: {
		4: "News Running",
		5: "Running-Training",
		6: "Ernährung",
		7: "Sportverletzungen",
		15: "Raquels Mizuno blog",
		31: "Athlètes sketchers",
		32: "Blogger Running Fashion",
		45: "Runnea Podcast",
		58: "New York Marathon",
		63: "Die Runners Mamas",
		71: "Outdoor",
		73: "Frauenteam Runnea",
		74: "Elektronik",
	},
	leaf: {
		features: {
			title: "Eigenschaften",
			list: {
				gender: "Geschlecht",
				cushioning: "Dämpfung",
				constitution: "Gewicht",
				footprint: "Pronation",
				surface: "Laufuntergrund",
				use: "Verwendung",
				beat: "Laufgeschwindigkeiten",
				foot: "Fuß",
				support: "Unterstützung",
				distance: "Distanz",
				weight: "Gewicht",
				weightf: "Damenen gewicht",
				weightm: "Herren gewicht",
				drop: "Sprengung",
				droptie: "Sprengung",
				colour: "Farbe",
				options: "Optionen",
				sensors: "Sensoren",
				connections: "Verbindungen",
				autonomy: "Akkulaufzeit (GPS Modus)",
				technology: "Technologie",
				impermeability: "Wasserdicht",
				sole: "Sohle",
				fabric: "Membran",
				sustainability: "Nachhaltigkeit",
				year: "Yahr",
			},
			values: {
				man: "Herren",
				woman: "Damen",
				low: "Gering",
				medium: "Mittle",
				high: "Hoch",
				short: "Kurzdistanz",
				light: "Leicht",
				strong: "Fort",
				neutral: "Neutral",
				supinator: "Unterpronation",
				pronator: "Überpronation",
				asphalt: "Asphalt",
				trail: "Trail",
				training: "Trainings",
				competition: "Leichathletik",
				mixed: "Hybrid",
				dig: "Hohlfuß",
				flat: "Plattfuß",
				normal: "Normal",
				heel: "Fersen",
				maraton: "Marathon",
				"carbon-plate": "Carbon",
				"half-maraton": "Halbmarathon",
				"10k": "10K",
				fitness: "Fitness",
				running: "Running",
				running_shoes: "Laufschuhe",
				cycling: "Radfahren",
				swimming: "Schwimmen/Tauchen",
				outdoor: "Outdoor",
				triathlon: "Triathlon",
				crossfit: "Crossfit",
				trekking: "Trekking",
				gps: "GPS",
				tactile: "Touchscreen",
				color_screen: "Farbbildschirm",
				maps: "Karten",
				pulse: "Handgelenk Puls",
				band: "Brustgurt",
				music: "Musik ohne Handy",
				spotify: "Spotify",
				strava: "Strava",
				altimeter: "Höhenmesser",
				thermometer: "Thermometer",
				tensiometer: "Blutdruckmesser",
				oximeter: "Sauerstoffmessgerät",
				barometer: "Barometer",
				bluetooth: "Bluetooth",
				wifi: "WLAN",
				nfc: "NFC",
				fm: "FM-Radio",
				sim: "SIM",
				"1-12": "1-12h",
				"13-24": "13-24h",
				"25-36": "25-36h",
				"37-48": "37-48h",
				48: "Über 48h Stunden",
				yes: "Ja",
				no: "Nein",
				"dry-line": "Dry-line",
				"gore-tex": "Gore-tex",
				"omni-tech": "Omni-tech",
				outdry: "Outdry",
				contagrip: "Contagrip",
				continental: "Continental",
				frixion: "Frixion",
				michelin: "Michelin",
				vibram: "Vibram",
				tiptoe: "Vorderer",
				vegans: "Vegane",
				"recycled-material": "Recyceltem Material",
				co2: "Kleiner CO2-Fußabdruck",
				yellow: "Gelb",
				blue: "Blau",
				white: "Weiß",
				grey: "Grau",
				brown: "Braun",
				purple: "Lila",
				orange: "Orangen",
				black: "Schwarz",
				red: "Rot",
				pink: "Rosa",
				green: "Grün",
			},
		},
		performances: {
			title: "Performances",
			lightness: "Leichtigkeit",
			cushioning: "Dämpfung",
			flexibility: "Flexibilität",
			responsive: "Antwort",
			stability: "Stabilität",
			grip: "Grip",
			durability: "Nachhaltigkeit",
			fit: "Anpassung",
			comfort: "Bequemlichkeit",
			quality: "Qualität",
		},
	},
	list: {
		filters: {
			title: "Filter",
			brand: "Marke",
			gender: "Geschlecht",
			price: "Preis",
			drop: "Sprengung",
			droptie: "Sprengung",
			cushioning: "Dämpfung",
			constitution: "Gewicht",
			footprint: "Pronation",
			surface: "Laufuntergrund",
			use: "Verwendung",
			beat: "Laufgeschwindigkeiten",
			foot: "FUß",
			support: "Unterstützung",
			distance: "Distanz",
			sustainability: "Nachhaltigkeit",
			size: "Größe",
			technology: "Technologie",
			fabric: "Membran",
			store: "Shop",
			colour: "Farbe",
			impermeability: "Wasserdicht",
			sole: "Sohle",
			options: "Optionen",
			type: "Typ",
			category: "Kategorie",
			lens_category: "Catégorie de lentille",
			lens_type: "Types de lentilles",
			filter: "Filter",
			province: "Département",
			region: "Region",
			country: "Land",
			year: "Jahr",
			month: "Monat",
			sensors: "Sensoren",
			connections: "Verbindungen",
			autonomy: "Akkulaufzeit (GPS modus)",
		},
		filtersValues: {
			man: "Herren",
			woman: "Damen",
			kid: "Kinder",
			low: "Gering",
			medium: "Mittle",
			high: "Hoch",
			mediumhigh: "Mittelhoch",
			"carbon-plate": "Carbon",
			light: "Leicht",
			soft: "soft",
			strong: "Stark",
			asphalt: "Asphalt",
			trail: "Trail",
			track: "Leichathletik",
			training: "Trainings",
			competition: "Competition",
			mixed: "Hybrid",
			dig: "Hohlfuß",
			flat: "Plattfuß",
			normal: "Normaler",
			tiptoe: "Vorderer",
			heel: "Talon",
			pronator: "Überpronation",
			neutral: "Neutral",
			supinator: "Unterpronation",
			short: "kurzdistanz",
			maraton: "Marathon",
			"half-maraton": "Halbmarathon",
			"10k": "10K",
		},
	},
	product_sorting: [
		{ value: "recommended_desc", text: "Relevanz" },
		{ value: "date_desc", text: "neuheiten" },
		{ value: "price_asc", text: "vom billigsten zum teuersten" },
		{ value: "price_desc", text: "vom teuersten zum teuersten" },
		{ value: "users_desc", text: "Nutzerbewertungen" },
		{ value: "review_desc", text: "Bewertung durch Experten" },
		{ value: "discount_desc", text: "Beste Rabatte" },
	],
	price_sorting: [
		{ value: "date_desc", text: "neuheiten" },
		{ value: "price_asc", text: "vom billigsten zum teuersten" },
		{ value: "price_desc", text: "vom teuersten zum teuersten" },
	],
	author: {
		written: "schrieb",
		of: "von",
		reviews: "test",
		articles: "Artikel",
		by: "von",
		Twitter: "Sie können mir über Twitter folgen",
		Instagram: "Sie können mir über Instagram folgen",
	},
	cookies: {
		text: "Wir verwenden eigene Cookies, um die Identifizierung der registrierten Nutzer beizubehalten und die Dienste personalisieren zu können, sowie analytische Cookies von Drittanbietern für Statistiken über die besuchten Seiten, jedoch immer in anonymer Form.",
		button: "Cookie Policy",
		accept: "Ich akzeptiere",
		deny: "Ich akzeptiere nicht",
	},
	article_list: {
		page_title: "Running Magazin | Runnea.de",
		meta_title: "Artikel zum Thema Running - Laufbekleidung und -schuhe",
		meta_keywords: "artikel, running, runnea, blog",
		meta_description:
			"Von Läufern und dem Runnea-Team veröffentlichte Laufartikel mit Tipps zu Ernährung, Training und Laufschuhen, um in Topform zu laufen.",
		page_title_section: "{seo}",
		meta_title_section: "Artikel {seo}",
		meta_keywords_section: "{seo}, artikel, runnea, magazine, blog",
		meta_description_section:
			"Artikel von {seo}, die von der Runnea-Redaktion veröffentlicht wurden. Tipps zu Ernährung, Training, Laufschuhen, Trailrunning und Wandern, um in Form zu bleiben.",
		page_title_author: "Magazine de running de {seo} ",
		meta_title_author: "Artikel von {seo} - Kleidung und Schuhe zum Laufen",
		meta_keywords_author:
			"{seo}, actualités, articles, running, courir, baskets, montres intelligentes, tapis de course, trekking, runnea, magazine, blog",
		meta_description_author:
			"Articles de running de {seo} avec des conseils sur la nutrition, l'entraînement, les chaussures de running pour courir en pleine forme.",
		page_title_text: "Magazin running '{seo}'",
		meta_title_text: "Running-Artikel '{seo}' - Kleidung und Schuhe zum Laufen",
		meta_title_tag_section: "{seo} - Kleidung und Schuhe zum Laufen",
		comparator: "Vergleiche",
		nutrition: "Ernährung",
		running_training: "Running-Training",
		sports_injuries: "Sportverletzungen",
		running_news: "Running News",
		podcast: "Podcast",
		outdoor: "Outdoor",
		electronics: "Elektronik",
		magazine: "Magazin",
	},
	article_page: {
		magazine: "RUNNEA Magazine",
		articles: "Artikel",
		categories: "Kategorien",
		share: "Teilen Sie",
		follow: "Folgen Sie uns",
		comments: "Kommentare",
		related_articles: "Verwandte Artikel",
		leave_comment: "Hinterlassen Sie Ihren Kommentar",
		registered: "Sie müssen registriert sein, um einen Kommentar abgeben zu können",
		register: "Anmelden",
		post: "Veröffentlichen",
		notifications: "Möchten Sie Benachrichtigungen erhalten, wenn ein Nutzer an diesem Chat teilnimmt?",
		subscribe: "Abonnieren",
		keywords: "laufshuche, marke, runnea",
		more_keywords: "magazin, runnea, blog",
		reportage: "Reportage",
		copy_to_clipboard: "URL in die Zwischenablage kopieren",
		copied_to_clipboard: "URL in die Zwischenablage kopiert",
		posted_on: "Gepostet am",
		read_more_category: "Lesen Sie weitere Neuigkeiten zu: ",
	},
	author_list: {
		page_title: "Experten im Laufen",
		meta_title: "Experten im Laufen, Experten im running",
		meta_keywords:
			"Tests, Autoren, Tester, Schuhe, Running, Marken, Beschreibung, Analyse, Bewertungen, Meinungen, Nutzer, Preise, Shops, Nachrichten, Tipps, Experten, Spezialisten, Ratgeber, Blog Ernährung, Trainings, Laufen, Leichtathletik",
		meta_description:
			"Spezialisten für Running, Ernährung , Training, Laufschuhe, um in Topform zu laufen und Ihnen zu helfen, den Laufschuh zu wählen, der am besten zu Ihren Eigenschaften passt, während Sie ihn zum besten Preis kaufen.",
		page_title_articles: "Autoren von Artikeln über Running",
		meta_title_articles: "Spezialisten für Running, Ernährung, Training und Laufschuhtester",
		meta_keywords_articles:
			"Tests, Autoren, Tester, Schuhe, Running, Marken, Beschreibung, Analyse, Bewertungen, Meinungen, Nutzer, Preise, Shops, Nachrichten, Tipps, Experten, Spezialisten, Ratgeber, Blog Ernährung, Trainings, Laufen, Leichtathletik",
		meta_description_articles:
			"Spezialisten für Running, Ernährung , Training, Laufschuhe, um in Topform zu laufen und Ihnen zu helfen, den Laufschuh zu wählen, der am besten zu Ihren Eigenschaften passt, während Sie ihn zum besten Preis kaufen.",
		page_title_reviews: "Tester von {seo}",
		meta_title_reviews: "Tester von {seo}",
		meta_keywords_reviews:
			"Tests, Autoren, Tester, Schuhe, Running, Marken, Beschreibung, Analyse, Bewertungen, Meinungen, Nutzer, Preise, Shops, News, Fragen, Fotos, Videos, Gutscheine, Rabatte",
		meta_description_reviews:
			"Spezialisten für Running, Ernährung, Training, Laufschuhe, um in Topform zu laufen und Ihnen zu helfen, den Laufschuh auszuwählen, der Ihren Eigenschaften am besten entspricht, während Sie ihn zum besten Preis kaufen.",
		authors: "Autoren.",
		showing_from: "Anzeige von",
		showing_to: "unter",
	},
	author_page: {
		magazine: "Running-Magazin von",
		articles: "Artikel",
	},
	brand_list: {
		brands: "Marken",
		title: "Marken von Running-Produkten | Runnea",
		keywords:
			"asics, adidas, nike, reebok, schuhe, running, marken, beschreibung, analysen, bewertungen, nutzer, preise, shops, fragen, fotos, videos, gutscheine, rabatte, community, kommentare",
		description: "Alle Marken von Laufprodukten in unserem Katalog, um Ihnen bei der Wahl des besten Laufschuhs zu helfen.",
		marks: "Marken von Running-Produkten",
	},
	store_page: {
		brands: "Hauptmarken",
		categories: "Produktkategorien",
		since: "Partnergeschäft von Runnea seit ",
		reviews: "Bewertungen",
		best_sold: "Bestseller von",
	},
	calculator: {
		page_title: "Zeitrechner",
		configure_sessions:
			"Konfigurieren Sie Ihre Trainingseinheiten und wir berechnen die Summe aller Intervalle, die Zwischenzeiten nach jedem Intervall und die Zwischenzeiten pro Kilometer. Sie können das Ergebnis auch ausdrucken oder speichern, um es jederzeit abrufen zu können.",
		how_works: "Wie es funktioniert",
		fill_two: "Füllen Sie zwei der drei Felder aus",
		of_each_interval: "von jedem Intervall und wir übernehmen die Berechnung des dritten.",
		insert_up: "Fügen Sie bis zu 20 Intervalle ein.",
		added_intervals: "Hinzugefügte Intervalle können kategorisiert oder gelöscht werden.",
		can_also: "Sie können auch eine Gruppe von Intervallen einfügen",
		to_which:
			"dem Sie eine Anzahl von Wiederholungen zuweisen können. Innerhalb der Gruppe können die Intervalle sortiert oder gelöscht werden.",
		save_your: "Zeichnen Sie Ihre Trainingseinheiten auf und Sie können jederzeit darauf zugreifen.",
		to_save: "Um die Sitzungen zu speichern, müssen Sie registriert sein.",
		interval: "Intervall",
		distance: "Distanz",
		acronym_meters: "(Meter)",
		rhythm: "Rhythmus",
		acronym_rhythm: "(Min/Km)",
		time: "Zeiten",
		actions: "Aktionen",
		raise_position: "Erhöhte Position",
		lower_position: "Niedrigere Position",
		remove: "Beseitigen",
		new_interval: "neues Gruppenintervall",
		total: "Gesamt",
		see_hide: "Zwischenergebnisse ansehen/ausblenden",
		print: "Drucken",
		save: "Speichern",
		modify: "Bearbeiten",
		group: "Gruppe",
		delete_all: "Alles löschen",
		calculate: "Berechnen",
		calculate_training: "Berechnen Sie Ihre Trainingszeiten",
		calculate_marathon: "Berechnen Sie Ihre Zeiten in einem Marathon",
		calculate_half_marathon: "Berechnen Sie Ihre Zeiten in einem Halbmarathon",
		calculate_ten: "Berechnen Sie Ihre Zeiten für 10 km",
		most_search: "Am meisten gesucht",
		adv: "Adv.",
		banner: "Banner",
		reps: "Reps.",
		error_missing: "Einige Intervalle haben nicht die nötigen Werte",
		error_empty: "Ein Feld leeren, um erneut rechnen zu können",
		error_not_valid: "Diese Werte sind ungültig, bitte korrigieren Sie sie.",
		error_need: "Wir benötigen mindestens zwei Werte, um die Berechnung durchzuführen",
	},
	calculator_10K: {
		page_title: "Zeitrechner 10 Km",
		insert_rhythm:
			"Geben Sie Ihr Tempo ein und wir berechnen die Zeit, die Sie für die 10 km brauchen würden, oder geben Sie die Zeit ein, die Sie schaffen wollen, und wir berechnen das Tempo, das Sie für die 10 km brauchen.",
	},
	calculator_half_marathon: {
		page_title: "Zeitrechner Halbmarathon",
		insert_rhythm:
			"Geben Sie Ihr Tempo ein und wir berechnen die Zeit, die Sie bei einem Halbmarathon laufen würden, oder geben Sie die Zeit ein, die Sie laufen wollen, und wir berechnen das Tempo, das Sie für einen Halbmarathon brauchen.",
	},
	calculator_marathon: {
		page_title: "Marathon-Zeitrechner",
		insert_rhythm:
			"Geben Sie Ihr Tempo ein und wir berechnen die Zeit, die Sie in einem Marathon laufen würden, oder geben Sie die Zeit ein, die Sie laufen wollen, und wir berechnen das Tempo, das Sie für einen Marathon brauchen.",
	},
	check_in: {
		checking_user: "Wir überprüfen Ihren Benutzernamen",
	},
	comparator_page: {
		page_title: "Vergleiche von {seo}",
		meta_title: "Vergleiche von {seo}",
		meta_keywords:
			"Vergleich, Running, Marken, Beschreibung, Analysen, Bewertungen, Nutzer, Preise, Shops, Fragen, Fotos, Videos, Gutscheine, Rabatte, Community, Kommentare",
		meta_description: "Vergleiche von {seo}, Hilfe bei der Auswahl {seo_the_best}.",
		in: "Bei",
		add: "Hinzufügen",
		best: "Beste",
		price: "preis",
		money_symbol: "€",
		reviews: "Test",
		queries: "Abfragen",
		benefits: "Vorteile",
		characteristics: "Eigenschaften",
	},
	comparison_page: {
		page_title: "{seo}",
		meta_title: "Vergleiche - {seo}",
		meta_keywords: "Vergleiche, schuhe, {seo}, running, kometare",
		meta_description:
			"Elemente zum Vergleichen und Auswählen, ob {seo} kaufen, indem Sie sich den Online-Vergleichsrechner auf einer einzigen Website ansehen.",
		in: "Bei",
		best: "Beste",
		price: "preis",
		money_symbol: "€",
		opinions: "Bewertungen",
		queries: "Fragen",
		benefits: "Vorteile",
		characteristics: "Eigenschaften",
		soon: "Bald",
	},
	discount_codes_list: {
		page_title: "Gutscheine für Running-Shops {year}",
		meta_title: "Gutscheine, um Ihre Laufschuhe billiger zu kaufen",
		meta_keywords: "running, gutscheine, rabatte, schuhe",
		meta_description: "Alle Gutscheine, die in Online-Shops erhältlich sind, damit Sie günstiger einkaufen können",
		go_to_shop: "Zum Shop gehen",
		coupon: "Gutscheincodes",
		copy_code: "Code kopieren",
	},
	gallery_page: {
		review: "Test",
		opinions: "Bewertungen",
		prices: "Preise",
		queries_recommendations: "Fragen und Empfehlungen",
		photos: "Bilder",
		news: "Nachrichten",
	},
	index: {
		covers: "Deckblätter",
		user_profile: "Nutzerprofil",
		sheet: "Details",
		sheet_of: "Details",
		product: "Produkt",
		products: "Produkte",
		prices: "Preise",
		race: "Lauf",
		marks: "Marken",
		article: "Artikel",
		articles: "Artikel",
		reviews: "Test",
		author: "Autor*",
		authors: "Autoren",
		cupons: "Gutscheincodes,",
		popular_race: "Courses à pied",
		query: "Abfrage",
		queries: "Abfragen",
		galery: "Galerie",
		comparative: "Vergleich",
		list: "Listen",
		list_of: "Liste von",
		filter_product: "(gefiltert nach Produkt)",
		filter_author: "(gefiltert nach Autor)",
		filter_two: "(gefiltert nach Autor und Produkt)",
		conList: "Artikel, Autoren, Fragen und Galerien",
		calculator: "Rechner",
		calculators: "Rechner",
		marathon: "Marathon",
		half_marathon: "Halbmarathon",
		ten_k: "10 km",
		link: "Link",
		redirecting: "Umleitung zu externen Preisen",
		components: "Komponenten",
		crisis: "(Crisecréative),",
	},
	inquiry_list: {
		meta_title: "{seo} | Runnea-Tipps",
		meta_keywords: "",
		meta_description: "",
		query: "Frage",
		inquiries: "Fragen",
		inquiries_pl: "Frage",
		inquiries_types_todas: "Alle",
		inquiries_types_1: "Mein idealer Schuh",
		showing_from: "Anzeige von",
		showing_to: "bis",
		find: "Suche",
		quick_search: "Schnelle Suche",
		category: "Kategorie",
		ask: "Stelle eine",
		consultorio: "Forum Runnea",
		make_a: "Stelle eine",
		required_field: "Pflichtfeld",
		receive_notifications: "Ich möchte Benachrichtigungen erhalten, wenn ein Nutzer an dieser Anfrage teilnimmt.",
		consult: "Eine Frage stellen",
		thanks: "Vielen Dank, dass Sie Ihre Anfrage eingereicht haben",
		error_try_again: "Beim Senden der Frage ist ein Fehler aufgetreten. Versuchen Sie es später noch einmal.",
		write: "Schreiben Sie Ihren Text hier",
		search_advanced: "Erweiterte Suche",
		search_keyword: "Schlüsselwort",
	},
	price_list: {
		outlet: "sale",
		page_title: "Sale {seo}",
		subpage_title: "Ausverkauf {year} {seo} große Rabatte ",
		meta_title: "{seo} im Sale - Outlet",
		meta_keywords: "{seo}",
		meta_description: "Günstige {seo} im Sale, mit den besten Rabatten und Angeboten um online zu kaufen.",
		in_value: "im {value}",
		of_value: "von {value}",
		for_value: "für {value}",
		with_value: "mit {value}",
		only_value: " {value}",
		filter_value: "{filter} {value}",
		in_filter_value: "sur {filter} {value}",
		of_filter_value: "de {filter} {value}",
		for_filter_value: "pour {filter} {value}",
		order_by: "Sortieren nach",
		show: "Anzeigen",
		shops: "Shops",
		lowcost: "Günstig",
		not_found: "Die Filter, die Sie für",
		not_found_extra: "haben uns nicht zu Ergebnissen verholfen.",
		page: "Seite",
		prices_of_product_type: "Preise von  {type}",
		of: "von",
	},
	product_list: {
		page_title: "Produkte {filter}",
		meta_title: "Produkte {filter} - Angebote zum Online-Einkauf",
		search_title: "Ergebnisse für ",
		search_product_title: "Produktergebnisse für die Suche ",
		search_article_title: "Artikel, die ",
		not_found: "Wir konnten keine Produkte oder Artikel für die Suche finden ",
		feedback_text: "Findest du nicht, wonach du suchst?",
		feedback_action: "Schicke uns deine Vorschläge",
		feedback_link: "/vorschlaege.html",
		show_more: "Zeig mehr",
		meta_keywords: "Running-Produkte {filter}",
		meta_description:
			"Liste von Produkte {filter} mit Test und Bewertungen von Läufern um die besten Laufschuh zum besten Preis zu kaufen.",
		list: "Liste von",
		show: "Anzeigen",
		of: "von",
		pag: "Seite",
		keywords: "marken",
		list_of: "Liste von",
		filters: "Filter",
		brand: "Marke",
		gender: "Geschlecht",
		price: "Preis",
		drop: "Sprengung",
		droptie: "Sprengung",
		cushioning: "Dämpfung",
		constitution: "Gewicht",
		footprint: "Pronation",
		surface: "Laufuntergrund",
		use: "Verwendung",
		beat: "Laufgeschwindigkeiten",
		foot: "Pied",
		support: "Appui",
		distance: "Distance",
		size: "Talles",
		technology: "Technologie",
		fabric: "Membran",
		store: "Shop",
		man: "Herren",
		woman: "Damen",
		kid: "Kinder",
		low: "Gering",
		medium: "Mittle",
		high: "Hoch",
		mediumhigh: "Mittelhoch",
		"carbon-plate": "Carbon",
		light: "Leicht",
		soft: "Süß",
		strong: "Stark",
		asphalt: "Asphalt",
		trail: "Trail",
		track: "Leichathletik",
		training: "Training",
		competition: "Competition",
		mixed: "Hybrid",
		dig: "Hohlfuß",
		flat: "Plattfuß",
		normal: "Normaler",
		tiptoe: "Vorderer",
		heel: "Fersen",
		pronator: "Überpronation",
		neutral: "Neutral",
		supinator: "Unterpronation",
		short: "Kurzdistanz",
		maraton: "Marathon",
		"half-maraton": "Halbmarathon",
		"10k": "10K",
		in_value: "auf {value}",
		of_value: "von {value}",
		for_value: "für {value}",
		with_value: "mit {value}",
		only_value: "{value}",
		filter_value: "{filter} {value}",
		in_filter_value: "auf {filter} {value}",
		of_filter_value: "von {filter} {value}",
		for_filter_value: "mit {filter} {value}",
		order_by: "Sortieren nach",
		visit: "Besuchen Sie unsere ",
		text: "Text",
		type: "Typ",
		outlet: "sale",
		showing_from: "(Anzeige der Ergebnisse von",
		to: "bis",
		new: "Neue",
		products: "Produkte",
		the: "die",
		most: "mehr",
		sold_out_fem: "verkauft",
		sold_out_masc: "verkauft",
		about: "über",
		users_speak: "die Nutzer sprechen darüber",
		relevance: "Relevanz",
		products_users_speak_about: "{article} {products} über die {article} Läufer im Moment sprechen",
		module_products_opinion_female: "{products}, die von den Benutzern am häufigsten getestet wurden",
		module_products_opinion_male: "{products}, die von den Benutzern am häufigsten getestet wurden",
		module_reviews: "Die neuesten {products}-Test",
		top_seller: "Meistverkaufte",
		position: "Position",
	},
	product_page: {
		analysis: "Analyse",
		compare: "Vergleich",
		comparer: "Vergleiche",
		show: "Siehe",
		show_prices: "Preise anzeigen",
		show_prices_man: "Alle Preise anzeigen Männer",
		show_prices_woman: "Alle Preise anzeigen Frauen",
		show_more_large: "Mehr Informationen zu diesem Modell",
		show_more: "Mehr",
		note: "Anmerkung vom",
		note_fem: "Anmerkung der",
		specialist: "Spezialist",
		community: "Gemeinschaft",
		leaf: "Details",
		review: "Test",
		review_title: "Test von {product}",
		reviews: "Test",
		reviews_likes_s: "1 Person fanden dies hilfreich",
		reviews_likes_p: "{number} Personen fanden dies hilfreich",
		reviews_like: "Nützlich",
		photos: "Bilder",
		opinions: "Bewertungen",
		opinions_of: "Bewertungen",
		inquiries: "Fragen",
		inquiries_of: "Fragen zu",
		similars: "Ähnlich",
		news: "Nachrichten",
		news_of: "Actualités aus",
		prices: "Preise",
		from: "Ab",
		in: "In",
		shops: "Shops",
		collect: "Wir sammeln Preise für",
		more: "Mehr",
		sellers: "Verkäufer",
		save: "Sparen Sie einen",
		save_and: "und bis zu",
		save_to: "Sparen Sie bis zu",
		releases: "in den Neuheiten",
		rest: "in den übrigen",
		size: "Größen",
		size_no: "Sie kennen Ihre Größe nicht",
		query_table: "Sehen Sie sich die Größentabelle von",
		username: "Vorname des Nutzers",
		user_querys: "Fragen von Nutzern",
		doubts: "Haben Sie Zweifel?",
		query_expert: "Konsultieren Sie unseren Experten",
		compare_with: "Vergleichen Sie mit",
		compare_with_label: "Vergleichen Sie ",
		with: "mit",
		conclusion: "Abschluss",
		average_rating: "Durchschnittliche Bewertung",
		user_average_rating: "Durchschnittliche Bewertung der Nutzer",
		user_rating: "Benutzer-Bewertung",
		from_users: "Von Nutzern",
		lightness: "Leichtigkeit",
		balance: "Stabilität",
		response: "Antwort",
		flexibility: "Flexibilität",
		damping: "Dämpfung",
		grip: "Griff",
		rate_product: "Bewerten Sie diesen Schuh",
		rated: "der Nutzer bewerteten dieses Modell von",
		find: "Finden Sie",
		displaying: "Unter  anzeigen",
		of: "von",
		querys: "Fragen",
		querys_comments: "Fragen und Kommentare",
		querys_comments_of: "Fragen und Kommentare über",
		show_comments: "Kommentare anzeigen",
		make_query: "Ich möchte eine Frage stellen",
		news_talk: "die Nachrichten sprechen über diesen Schuh",
		community_talk: "Vertrauen Sie der Runnea-Community, unsere Nutzer sprechen über",
		this: "diese",
		this_masc: "diese",
		features: "Eigenschaften -",
		features_of: "Eigenschaften der",
		features_and_opinions: "Test und Bewertungen",
		best_information: "mit Informationen, um die beste zu wählen ",
		buy_at: "Zum besten Preis einkaufen",
		best_price: "Beste Preis",
		benefits: "Vorteile",
		photo_gallery: "Fotogalerie",
		original_price: "Originalpreis",
		gender: "Geschlecht",
		sizes: "Größen",
		choose_size: "Wählen Sie Ihre Größe",
		no_prices_soon: " In den letzten Tagen haben wir keine Preise für:",
		no_prices_soon_vars:
			"In den letzten Tagen haben wir keine Preise für: {product} mit Größe {filtersSize} gefunden. Versuche es mit einer anderen Größe.",
		your_selection: "Deine Auswahl",
		no_prices: "Seien Sie der Erste, der eine Benachrichtigung erhält, wenn Preise verfügbar sind.",
		keywords:
			"laufschuhe, Marken, Beschreibung, Tests, Bewertungen, Nutzer, Preise, Shops, Fragen, Fotos, Videos, Gutscheine, Rabatte, Community, Kommentare",
		photo_galery: "Fotogalerie ",
		lower_query: "Fragen",
		lower_opinions: "Bewertung",
		min: "Min:",
		max: "Max:",
		anonymous: "Anonym",
		no_comments: "Ohne Kommentar",
		no_queries: "Keine Fragen",
		no_opinions: "keine Bewertungen",
		be_first_opinion: "Seien Sie der Erste, der eine Bewertung abgibt",
		more_compared: "am meisten verglichen",
		size_guide: "Größentabelle",
		tester: "Tester",
		testers: "Das sagen unsere Tester",
		added_product: "Das Produkt wurde zum Vergleich hinzugefügt",
		go_to_comparator: "Zum Vergleichsrechner gehen",
		see_more_prices: "Mehr Preise anzeigen",
		more_prices_text: "Entdecken Sie weitere Preise",
		more_prices_of: "für",
		featured_prices: "Beste Angebote",
		also_from: "Andere Produkte von",
		characteristics: "Eigenschaften der",
		with_information: ", mit Informationen zur Auswahl der besten",
		money_acronim: "EUR",
		see: "Siehe",
		products_of: "Produkte von",
		related_to: "die mit der Suche verbunden sind",
		page: "Seite",
		lower_page: "Seite",
		score_text: "Anmerkung RUNNEA",
		video: "Video",
		watch_video: "Video review ansehen",
		runnea_score_title: "Was ist der RUNNEA SCORE?",
		runnea_score_info:
			"Der RUNNEA SCORE ist eine Bewertung, die auf der Grundlage von Produkteigenschaften, Nutzerinteresse, Kundenmeinungen und dem Preis-Leistungs-Verhältnis erstellt wird.",
		ranking_text: "<strong>{product}</strong> auf <b>Platz {position}</b> in der Rangliste der bestbewerteten {type}.",
		ranking_view: "Ranking anzeigen",
		awards: "Auszeichnungen",
		pros: "Vorteile",
		cons: "Nachteile",
		question: "Frage",
		question_first_letter: "F",
		answer: "Antwort",
		answer_first_letter: "A",
		answers: "Antworten",
		fan_title: "st du ein Fan der",
		fan_text: "Alle Modelle ansehen von:",
		expert: "Experte",
		need_know: 'Was Sie wissen sollten',
		runner_type: 'Läufertyp',
		ideal_for: 'Ideal für',
		strengths: 'Stärken',
		rivals: 'Konkurrenten',
		weight: 'Gewicht'
	},
	product_prices: {
		page_title: "{seo}",
		meta_title: "{seo} | {precio_desde} | {fecha}{pagina}",
		meta_gender_title: "{seo} | {precio_desde} | {fecha}{pagina}",
		meta_keywords: "{seo} ",
		meta_description: "Preisliste von {seo} , mit Test und Bewertungen um den besten Laufschuh zu kaufen.",
		best_offer: "Das beste Angebot",
		showing: "Anzeige",
		showing_total_prices: "Anzeige {total} {seo}",
		users_favourites: "Die Favoriten der Nutzer",
		compare_with: "Vergleichen Sie mit",
		find_more_products: "Finden Sie {type} {brand}",
		in_value: "auf {value}",
		of_value: "von {value}",
		for_value: "für {value}",
		with_value: "mit {value}",
		only_value: "{value}",
		filter_value: "{filter} {value}",
		in_filter_value: "auf {filter} {value}",
		of_filter_value: "von {filter} {value}",
		for_filter_value: "für {filter} {value}",
		show_prices: "Siehe Preise",
		product_cheaper: "Möchten Sie diese {type} plus {lowcost}?",
		alert: "Wir werden Sie informieren, wenn sie verfügbar sind",
		add_alert: "Warnung hinzufügen",
		average_grade: "Durchschnittliche Bewertung der Runner",
		favorites: "Die Favoriten der Nutzer",
		fill_position: "Besetzt das Ranking",
		best_note: "amit den besten Nutzerbewertungen",
		of: "von",
		from: "von",
		to: "unter",
		prices: "Preise",
		order_by: "Sortieren nach",
		show_filters: "Filter anzeigen",
		prices_of: "Preise von Laufschuhen",
		page: "Seite",
		lower_page: "Seite",
		for_selected: "für die ausgewählten Filter",
		send: "Senden",
		country_from: "Besuchst du uns aus {country}?",
		country_change: "Wähle deine Geolokalisierung",
	},
	race_list: {
		page_title: "Courses à pied{seo}",
		meta_title: "Courses à pied{seo}, Calendrier des meilleurs courses",
		meta_keywords: "courses, populaires, marathons, duathlons",
		meta_description:
			"Calendrier des courses à pied{seo}, des informations complètes sur les inscriptions, le parcours, le lieu et l'heure de départ.",
		showing: "Affichant",
		showing_total_races_seo: "Affichant {total} courses à pied{seo}",
		in_value: "en {value}",
		of_value: "de {value}",
		for_value: "pour {value}",
		with_value: "avec {value}",
		only_value: "{value}",
		filter_value: "{filter} {value}",
		in_filter_value: "en {filter} {value}",
		of_filter_value: "de {filter} {value}",
		for_filter_value: "pour {filter} {value}",
		race_year: "Running : Courses à pied en {year}",
		find: "Recherchez votre course",
		not_found: "Aucun contenu trouvé pour cette recherche",
		description: "Date, délai d'inscription, parcours et classements de",
		start: "Départ",
		filters: {
			distance: "Distance",
			surface: "Surface",
			province: "Province",
			country: "Pays",
			month: "Mois",
			year: "année",
		},
	},
	race_page: {
		meta_title: "{seo}",
		meta_keywords:
			"courir, {seo}, heure, départ, arrivée, affiche, participants, durée, lieu, horaire, règlement, coureurs, runners, athlétisme, running, course populaire, coureur, amateur, populaire, athlètes, actualités",
		meta_description:
			"Informations utiles pour courir en {seo} avec des détails sur l'inscription, le parcours, le lieu et l'heure de départ, les classements, les coureurs et les habitudes d'entraînement.",
		tagline: "Date, inscription, parcours et informations générales",
		browser_title: "Running: Courses à pied dans {year}",
		races_title: "Courses à pied",
		score_general: "Géneral",
		score_route: "Parcours",
		score_organization: "Organisation",
		score_ambience: "Environnement",
		score_refreshments: "Rafraîchissements",
		poster: "Affiche",
		average_rating: "Évaluation moyenne",
		from_users: "Des utilisateurs",
		view_race_web: "Voir web de la course",
		leaf: "Fiche",
		inscriptions: "Inscriptions",
		route: "Parcours",
		forum: "Forum",
		verify: "Verifier",
		feature_date: "Date",
		feature_place: "Lieu",
		feature_province: "Province",
		feature_country: "Pays",
		feature_surface: "Surface",
		feature_time: "Heure de départ",
		feature_participants: "Participants",
		feature_contact: "Contact",
		feature_organizer: "Organisateur",
		feature_distance: "Distance",
		something: "Quelque chose",
		heart_rate_monitors: "Moniteurs de fréquence cardiaque",
		popular_races: "Courses à pied",
		outlet: "Outlet",
		description: "Description",
		will_be: "aura lieu le",
		in: "in",
		anyway_is_important: "In jedem Fall ist es wichtig, dass Sie das Datum auf der Website des Organisators bestätigen",
		as_organizers:
			"Sobald die Organisatoren uns die Strecke für diese Ausgabe mitgeteilt haben oder die Anmeldephase eröffnet ist, werden wir die entsprechenden Informationen veröffentlichen. Falls Sie Fragen zu diesem Lauf haben, können Sie diese gerne stellen an",
		of: "von",
		or_consult: "oder besuchen Sie die Website der Rennorganisation.",
		inscription: "Datum der Einschreibungen in die",
		if_you_want: "Wenn Sie wissen möchten, wann die Anmeldefrist für diese Ausgabe der",
		be_aware: "bleiben Sie auf unserer Website und in unserem Forum der",
		because: "weil wir punktuell informieren werden.",
		unfold_info: "Während die Details der Strecke für die diesjährige Ausgabe der",
		will_inform: "wir werden Sie auf dem Laufenden halten.",
		questions_doubts: "Wenn Sie Fragen oder Zweifel zum Rennen haben, können Sie Ihre Fragen an unser Forum der",
		fill_form: "Füllen Sie dieses Formular aus, damit wir es validieren können.",
		organizer: "Sind Sie der Organisator des Rennens?",
		verify_race: "Den Lauf überprüfen",
	},
	redirecting: {
		redirecting: "Wir leiten Sie auf die offizielle Website weiter",
		value_proposal:
			"Bei RUNNEA vergleichen wir die Preise in mehr als 150 verifizierten Online-Shops, um Ihnen das beste Ergebnis zu bieten, ohne dass Ihnen zusätzliche Kosten entstehen.",
		coupon_advice: "Um den Preis zu erhalten, auf den Sie geklickt haben, verwenden Sie bitte den Rabattcode!",
		accept_button: "Verstanden",
	},
	review_list: {
		page_title: "Tests von {seo}",
		meta_title: "Tests von {seo}",
		meta_keywords: "Schuhe, Test, Bewertungen, Running, Wandern, Trail, Nutzer, Preise, Shops, Anfragen, Community, Kommentare, {seo}",
		meta_description: "Tests von {seo} | Runnea, eine Hilfe bei der Auswahl des besten Laufschuhs.",
		reviews: "tests | test | tests",
		showing_from: "Anzeige von",
		showing_to: "unter",
	},
	opinion_list: {
		page_title: "Bewertung von {seo}",
		meta_title: "Bewertung von {seo}",
		meta_keywords:
			"Marken, Beschreibung, Tests, Bewertungen, Nutzer, Preise, Shops, Fragen, Fotos, Videos, Gutscheine, Rabatte, Community, Kommentare, Schuhe, {seo}.",
		meta_description: "Bewertung{seo} | Runnea, eine Hilfe bei der Auswahl des besten Laufschuhs.",
		opinions: "Bewertung | Bewertungen | Bewertungen",
		showing_from: "Anzeige von",
		showing_to: "unter",
	},
	login: {
		session: "Sich identifizieren",
		welcome: "Willkommen",
		forget: "Ich habe mein Passwort vergessen",
		send: "Senden",
		back: "Zurück",
		genericerror: "Fehler beim Verbinden",
		closeSession: "Sitzung schließen",
		no_account: "Ich habe noch kein Konto, ich möchte mich anmelden",
		logout: "Sich abmelden",
		login: "Sich anmelden",
		register: "Registrierung",
		incorrect_email: "Falsche E-Mail",
		incorrect_password: "Falsches Passwort",
		remmember_password: "Sich an das Passwort erinnern",
	},
	reset_password: {
		new: "Neues Passwort",
		repeat: "Passwort wiederholen",
		send: "Senden",
		reset_password: "Geben Sie Ihr neues Passwort ein, um auf Ihr Konto zuzugreifen.",
		change: "Ändern des Passworts",
		different_passwords: "Die Passwörter sind unterschiedlich",
		description: "Das Passwort muss 8 Zeichen lang sein, alphanumerisch und Großbuchstaben enthalten.",
		successfully_update: "Sie haben Ihr Passwort geändert. Sie können nun auf die Seite gehen, um sich anzumelden",
		unknown_error: "Unbekannter Fehler",
		error: "Fehler",
	},
	register: {
		username: "Name des Nutzers",
		email: "E-mail",
		name: "Vorname",
		sex: "Geschlecht",
		man: "Herr",
		woman: "Damme",
		country: "Land",
		postalCode: "Código postal",
		brand: "Haben Sie eine Lieblingsmarke?",
		size: "Größe",
		send: "Senden",
		facebook: "Mit Facebook anmelden",
		google: "Mit Google anmelden",
		password: "Passwort",
		repeat_password: "Wiederholung des Passworts",
		Emailnotcorrect: "Die E-Mail ist nicht korrekt",
		Passwordsdontmatch: "Die Passwörter stimmen nicht überein",
		LowercaseLetterRequired: "Ein Kleinbuchstabe ist erforderlich.",
		UppercaseLetterRequired: "Ein Großbuchstabe ist erforderlich.",
		"8Characters": "Mindestens 8 Zeichen.",
		OneNumber: "Eine Zahl ist erforderlich.",
		PasswordIncorrect:
			"Das Passwort ist nicht korrekt. Überprüfen Sie, ob es Kleinbuchstaben, Großbuchstaben, mehr als 8 Zeichen und einige Zahlen enthält.",
		registrate: "Sich anmelden",
		successfully_registered: "Erfolgreich registriert",
		not_exist_email: "Die E-Mail existiert nicht",
		remember_ok: "Wir haben Ihnen eine E-Mail an die Adresse {email}. Überprüfen Sie Ihren Posteingang, um Ihr Passwort abzurufen.",
		user_not_found: "Nicht vorhandener Nutzer",
		error_recovering: "Fehler bei der Datenwiederherstellung",
		email_exists: "Die E-Mail existiert bereits",
		email_not_found: "Die E-Mail wurde nicht gefunden",
		missing_fields: "Fehlende Felder zum Ausfüllen",
		incorrect_format: "Falsches Format",
	},
	verify: {
		name_surname: "Name und Vorname",
		email: "E-mail",
		tlf: "Telefonnummer",
		days: "Wochentage, an denen wir Sie kontaktieren können",
		hour: "Zeiten, zu denen wir Sie kontaktieren können",
		send: "Senden",
		monday: "Montag",
		tuesday: "Dienstag",
		wednesday: "Mittwoch",
		thursday: "Donnerstag",
		friday: "Freitag",
		extra: "Zusätzliche Informationen",
		emailnotcorrect: "Die E-Mail ist nicht korrekt",
		verified:
			"Vielen Dank für das Ausfüllen des Formulars. Wir werden uns mit Ihnen in Verbindung setzen, um die Informationen zu bestätigen.",
		not_verified: "Etwas ist schief gelaufen, versuchen Sie es später noch einmal.",
		missing_fields: "Es gibt fehlende Felder, die ausgefüllt werden müssen",
		accept: "Akzeptieren Sie die",
		acceptPol: "Datenschutzrichtlinie",
		form_first: "Sind Sie Veranstalter eines Rennens? Dank des Verifizierungsprozesses werden Sie Folgendes geben können",
		form_first_bold: "mehr kostenlose Sichtbarkeit für Ihre Veranstaltung.",
		form_second: "Wenn Sie der Organisator sind oder Teil des Teams, das die Veranstaltung organisiert, sind",
		form_second_after_bold:
			"füllen Sie das untenstehende Formular aus. Wir werden uns mit Ihnen in Verbindung setzen, um den Überprüfungsprozess abzuschließen.",
	},
	registration: {
		already_account: "Ich habe bereits ein Konto, ich möchte mich anmelden",
		continue: "Weiter",
	},
	talk_page: {
		consultant: "Kabinett",
	},
	user_page: {
		modify: "Foto bearbeiten",
		save: "Änderungen speichern",
		select: "Wählen Sie",
	},
	not_found_page: {
		pageNotFound: "Seite nicht gefunden",
		pageNotFoundMsg:
			"Das ist fast noch ärgerlicher als ein kleiner Stein, der sich mitten in einem Lauf oder einer tollen Wanderung in deinen Schuh verirrt.\nSie können zurückblättern oder zur Startseite zurückkehren.",
		goHome: "Zur Startseite gehen",
	},
	article_finder: {
		find_here: "Nach einem Artikel suchen",
	},
	AuthorLeaf: {
		has_written: "schrieb",
		num_reviews_of: "0 Tests von  | 1 Test von  | {count} Tests von ",
		of: "von",
		reviews: "tests",
		articles: "artikel",
	},
	AuthorMiniLeaf: {
		by: "von",
	},
	BrandCard: {
		size_chart: "Größentabelle",
		go_to_size_chart: "Zur Größentabelle gehen",
	},
	BrandsProduct: {
		also: "Andere Produkte von",
		from: "Ab",
		money: "€",
		find: "Finden Sie Ihre Laufschuhe",
	},
	DiscountCodeCard: {
		see: "Siehe Reduktion",
		share: "Teilen",
		go_to_store: "Zum Shop gehen",
		copied: "Kopiert",
		special_cupon: "Spezieller Coupon",
		date_up_to: "Bis zum",
		read_more: "Mehr erfahren",
	},
	ModuleProductFeatureCard: {
		our_selection: "Unsere Auswahl",
	},
	InquiryTitleCard: {
		other_queries: "Weitere Fragen zum Thema Running",
	},
	ModuleCarousel: {
		new: "Neuheit!",
		upper_new: "NEUHEITEN",
		discover: "Entdecken Sie",
		exclusive: "Exklusiv",
		text_1: "Virtuelle Rennen kommen Runnea",
		text_2: "Runnea präsentiert Ihnen eine Liste der besten virtuellen Läufe in",
	},
	ModuleChats: {
		title: "Fragen und Kommentare von {content}",
		showing: "Affichant {start} de {end} questions ",
		show_all: "Siehe {total} Nutzerfragen",
		no_contents: "Keine Fragen oder Kommentare",
	},
	ModuleComments: {
		title: "Kommentare von {content}",
		showing: "Anzeige {start} von {end} Kommentaren",
		show_all: "Siehe {total} Nutzerfragen",
		no_contents: "Keine Kommentare",
	},
	ModuleForm: {
		field: "Das Feld",
		is_required: "ist obligatorisch",
		query_received:
			"Wir haben Ihre Anfrage erhalten. Wir werden uns innerhalb von 48 Stunden mit Ihnen in Verbindung setzen. Vielen Dank, dass Sie sich mit uns in Verbindung gesetzt haben runneante.",
		error_submitting: "Fehler beim Absenden des Formulars",
		go_to_form: "Zum Formular",
	},
	ModuleMenu: {
		running_shoes: "Laufschuhe",
		trekking_shoes: "Trekkingschuhe",
		trail_shoes: "Trail Running Schuhe",
		treadmill: "Laufbänder",
		pulsometer: "Pulsuhr",
		running_training: "Training Running",
		menu: "Menü",
		login: "Sich einloggen",
		logout: "Logout",
		account: "Mein Konto",
		title_ra: "Entraîneur Personnel en ligne Running et Trail Running",
		claim: "Wir helfen Ihnen, besser zu laufen !",
		categories: "Alle Kategorien",
	},
	ModulePricesBargain: {
		new: "Neues Angebot",
		discount: "Rabatt",
		go: "Zum Angebot",
		click: "1 Klik",
		clicks: "{clicks} Kliks",
	},
	newChatButton: {
		description:
			"Si tienes dudas en un tema específico, puedes elegir a un experto para que te ayude. O si lo prefieres, puedes lanzar una pregunta a la comunidad y que te responda cualquiera de nuestros expertos u otros usuarios.",
		options: "Elige una opción",
		your_inquiry: "Tu consulta",
		your_inquiry_for: "Tu consulta para:",
		to_do: "Machen",
		do: "Machen Sie",
		quest: "Wählen Sie ein Thema und stellen Sie Ihre Frage.",
		inquiry: "frage | fragen",
		inquiries: "Keine Fragen | Eine Frage | Die Fragen",
		select_category: "Wählen Sie eine Kategorie",
		inquiry_description: "Beschreibung der Frage",
		write_your_text: "Schreiben Sie Ihren Text hier",
		required_field: "Pflichtfelder",
		receive_notifications: "Ich möchte Benachrichtigungen erhalten, wenn ein Nutzer an dieser Frage teilnimmt.",
		not_logged_in: "Sie sind nicht angemeldet",
		please_login_msg: "Sie sind nicht eingeloggt. Bitte melden Sie sich an",
		thanks: "Vielen Dank, dass Sie Ihre Anfrage eingereicht haben",
		try_again: "Beim Absenden der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
		mentioned_shoes: "Erwähnte Laufschuhe",
		new_inquiry: "Neue Frage",
		new_inquiry_text: "Wenn Sie Fragen zu Laufausrüstung haben, können Ihnen unsere Experten weiterhelfen.",
		modal: {
			title: "Pregunta a un experto",
			text: "Si necesitas aclarar cualquier duda sobre material de running escríbenos y nuestro equipo de expertos te ayudará en todo lo que pueda.",
		},
	},
	newCommentButton: {
		reply: "Antworten",
		inquiry: "frage | fragen",
		write_your_text: "Écrivez votre texte ici",
		required_field: "Pflichtfelder",
		receive_notifications: "Ich möchte Benachrichtigungen erhalten, wenn ein Nutzer an dieser Frage teilnimmt.",
		not_logged_in: "Sie sind nicht angemeldet",
		please_login_msg: "Sie sind nicht eingeloggt. Bitte melden Sie sich an",
		thanks: "Danke, dass Sie sich an der Anfrage beteiligt haben",
		try_again: "Beim Absenden des Kommentars ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
	},
	newOpinionButton: {
		new: "Hinterlassen Sie uns Ihre Meinung",
		not_logged_in: "Sie sind nicht angemeldet",
		please_login_msg: "Sie sind nicht eingeloggt. Bitte melden Sie sich an",
		performance: "Dienste",
		comment: "Kommentar",
		opinion: "Geben Sie Ihre Meinung",
		score: "Punktzahl",
		write: "Schreiben Sie Ihre Meinung",
		thanks:
			"Ein großes Dankeschön vom gesamten RUNNEA-Team und der gesamten RUNNEA-Läufergemeinschaft. Ihre Meinung ist wertvoll, denn sie wird uns allen helfen, eine bessere Wahl zu treffen. Zögern Sie nicht, uns Ihre Meinung zu anderen Modellen zu schicken.",
		next: "In wenigen Stunden werden Sie Ihre Meinung im Internet veröffentlicht sehen.",
		try_again: "Beim Absenden Ihrer Meinung ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
	},
	newCommentModule: {
		leave_comment: "Hinterlassen Sie Ihren Kommentar",
		registered: "Sie müssen angemeldet sein, um Kommentare zu hinterlassen",
		register: "Registrieren",
		placeholder: "Schreiben Sie Ihre Meinung",
		required_field: "Pflichtfelder",
		post: "Veröffentlichen",
		thanks_msg: "Kommentar wurde gesendet. Vielen Dank für Ihre Teilnahme.",
		error_msg: "Beim Absenden des Kommentars ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
	},
	NewsLettersCard: {
		message: "Informieren Sie sich über die neuesten Nachrichten aus der Welt des running und über Angebote für Sportgeräte.",
		highlight: "Werden Sie ihn verpassen?",
		remember: "Vergessen Sie nicht, die Bedingungen zu akzeptieren",
		successful: "Wir haben Sie soeben registriert.",
		error: "Es ist ein Fehler aufgetreten, Runneante. Bitte versuchen Sie es später noch einmal",
		error_try: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal",
		required: "Pflichtfelder",
		thanks: "Danke",
	},
	ProductCardPrincipal: {
		see: "Siehe Details",
		see_description: "Alle Funktionen sehen",
	},
	ProductHeaderButtons: {
		alert_received:
			"Sehr gut, Ihr Preisalarm wurde eingerichtet. Wenn wir einen Preis entdecken, der Ihren Kriterien entspricht, senden wir Ihnen einen Alarm.",
		error_submitting: "Die Warnung konnte nicht erstellt werden. Bitte versuchen Sie es erneut.",
		compare: "Vergleich",
		alert: "Ich möchte informiert werden, wenn der Preis sinkt",
		available: "Benachrichtige mich, sobald es verfügbar ist",
		disable_alert: "Alert",
		disable_price_alert: "Preiswarnung ausschalten",
		close: "Schließen",
		our_comparator:
			"Unser Preisvergleich prüft jeden Tag Millionen von Preisen. Richten Sie Ihre Preiswarnungen ein und wenn wir einen Preis finden, der Ihren Kriterien entspricht, senden wir Ihnen eine Warnung.",
		model: "Preisalarm für ",
		sure: 'Klicken Sie auf die Schaltfläche "Bestätigen", um diese Preiswarnung zu deaktivieren.',
		confirm: "Bestätigen",
		alert_deleted:
			"Wir konnten die Preiswarnung, die Sie für die Vorlage {marca} {producto} eingerichtet haben, nicht entfernen. Kontaktieren Sie uns, damit wir ihn entfernen können.",
		error_deleting_alert:
			"Wir konnten die Preiswarnung, die Sie für die Vorlage {marca} {producto} eingerichtet haben, nicht entfernen. Kontaktieren Sie uns, damit wir ihn entfernen können.",
		new_version: "Neue Version verfügbar",
		new_version_btn: "Neue Version anzeigen",
		old_version: 'Das aktuelle Modell ist:',
		old_version_btn: 'Details anzeigen'
	},
	ProductFeedback: {
		chat_1: "Hallo{user}",
		chat_2: "Was können Sie uns über den {product} erzählen?",
		state_wish: "Ich liebe ihn",
		state_have: "Ich habe ihn",
		state_dislike: "Ich mag ihn nicht",
		votes: "Stimmen",
		response_wait: "...",
		response_ok: "Danke für Ihre Teilnahme!",
		response_ko: "ERROR",
	},
	ContentPremium: {
		title: "Melden Sie sich mit Ihrem Konto an, um diesen Inhalt zu sehen",
		description: "Registrieren Sie sich KOSTENLOS und genießen Sie alle unsere exklusiven Inhalte",
		advantages_1: "Unbegrenzter Zugang zu eingeschränkten Artikeln",
		advantages_2: "Newsletter mit personalisierten Inhalten",
		advantages_3: "Verlosungen und Gewinnspiele",
	},
	SliderCardFinder: {
		find: "Suche",
	},
	UserPasswordForm: {
		new_password: "Geben Sie Ihre E-Mail-Adresse von Runnea ein und wir senden Ihnen ein neues Passwort zu.",
	},
	UserRegisterForm: {
		continue_with: "Weiter mit",
		or: "o",
		start: "Ergänzen Sie einige Details und beginnen Sie!",
		required: "Pflichtfelder",
		access_data: "Ihre Zugangsdaten",
		incorrect_user: "Falscher Identifikator",
		remmember_password: "Sich an das Passwort erinnern",
		congratulations: "Herzlichen Glückwunsch!",
		welcome: "Willkommen in der Runnea-Community",
		complete: "Füllen Sie einige Details aus und beginnen Sie!",
		no_account: "Ich habe kein Konto",
		name: "Name",
		surname: "Vorname",
		user: "Benutzer",
		email: "E-mail",
		password: "Passwort",
		tread_type: "Type de foulée",
		dont_know: "Ich weiß nicht",
		pronator: "Pronator",
		neutral: "Neutral",
		supinator: "Supinierer ",
		register_conditions: "Besondere Registrierungsbedingungen",
		commercial_communications: "Ich möchte Werbeinformationen per E-Mail erhalten.",
		legal:
			'Ich akzeptiere die <a href="https://www.runnea.de/impressum-agb.html" target="_blank" rel="noopener noreferrer" class="legal_notice">Datenschutz</a>. Durch die Einrichtung deines Kontos erklärst du dich mit den Geschäftsbedingungen von Runnea einverstanden.',
		rgpd: 'Wir werden Ihre Daten verarbeiten, um Ihre Anfrage zu bearbeiten und zu beantworten. Sie können Ihre Rechte auf Auskunft, Berichtigung, Löschung, Widerspruch, Einschränkung der Verarbeitung und Übertragbarkeit ausüben, indem Sie uns eine E-Mail senden an <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Weitere Informationen finden Sie in der <a href="https://www.runnea.de/privatsphare.html" target="_blank" rel="noopener noreferrer nofollow">Datenschutzerklärung</a>.',
	},
	UserAuthForm: {
		wrong_password: "Falscher Benutzer oder falsches Passwort",
		no_email: "Die E-Mail existiert nicht",
	},
	influencer_card: {
		reviews: "Tests",
		last_review: "Letzter Test",
		best_review: "Bester Test",
		show_price: "Preis anzeigen",
	},
	inquiry_card: {
		comments: "Kommentare anzeigen",
		no_comments: "Ohne Kommentar",
	},
	inquiry_finder: {
		find_text: "Filtrer par texte",
		find_product: "Nach Produkt filtern",
	},
	list_filters: {
		filters: "Filter",
		filter: "Filtern",
		return: "Zurück",
		delete: "Löschen",
		sizes: "Größen",
		show: "Anzeigen",
		results: "Ergebnisse",
		example: "Ej. Nike",
		more_brands: "Mehr Marken",
	},
	list_pagination: {
		before: "Vorherige",
		next: "Weiter",
		first_page: "Erste Seite",
		last_page: "Letzte Seite",
	},
	price_card: {
		shipping_costs: "Senden",
		from_price: "ab {price} €",
		buy: "Kaufen",
		go_to_store_w_price: "Zum <i>{store}</i> Shop gehen und für {price} € kaufen",
		free_shipping: "Kostenlose Lieferung",
		show_offer: "Siehe Angebot",
		till: "Bis zum",
		coupon: "Coupon",
		coupon_no: "Kein Code",
		man: "Herren",
		woman: "Damen",
		kid: "Kinder",
		unisex: "Herren - Damen",
	},
	product_card_price_opinion: {
		let_opinion: "Hinterlassen Sie uns eine Meinung",
		opinions: "Bewertungen",
		questions: "Fragen",
	},
	product_finder: {
		find_here: "Hier suchen",
	},
	brand_finder: {
		find_here: "Nach Marke filtern",
	},
	race_finder: {
		find_here: "Einen Artikel suchen",
		search: "Suche",
	},
	module_finder: {
		title: "Laufschuhe",
		text1: "Finden Sie den besten Preis unter mehr als 20.000 Schuhen",
		text2: "Wir vergleichen mehr als 2.000.000 Laufschuhe von führenden Marken",
		placeholder: "Laufschuhe",
		need_help: "Benötigen Sie Hilfe bei Ihrer Wahl??",
		just_ask: "Fragen Sie unsere Spezialisten",
		where_start: "Nicht sicher, wo Sie anfangen sollen?",
		use_shoe_finder: "Probieren Sie unseren Empfehlungsdienst!",
		search_by_brand: "Suche nach Marke",
	},
	footer: {
		about: "Über uns",
		newsletter: "Newsletter",
		publicity: "Werbung",
		product_test: "Produkttester",
		shops: "Dein Shop und deine Produkte",
		products: "Produkte",
		contact: "Kontakt",
		legal: "Impressum / AGB",
		privacity: "Datenschutz",
		cookies: "Cookie-Einstellungen",
		work: "Karriere",
		who: "Unser Team",
		legal_information: "Rechtliche Hinweise",
		license: "",
		other_webs: "Andere Webseiten von RUNNEA",
		copyright: "RUNNEA Copyright",
		follow: "Folge uns auf",
		union: "",
		union_deep: "",
		union_mes: "",
		union_mode: "",
		disclaimer: "RUNNEA kann eine Provision vom Verkäufer erhalten, wenn Sie über den Partnerlink kaufen.",
	},
	product_header: {
		specialist: "Anmerkung des Spezialisten",
		community: "Bewertung der Gemeinschaft",
		leaf: "Details",
		review: "Test",
		opinions: "Bewertung",
		inquiries: "Fragen und Empfehlungen",
	},
	user_auth_form: {
		facebook: "Anmeldung mit Facebook",
		google: "Anmeldung mit Google",
	},
	youtube: {
		suscribe: "ABONNIEREN SIE DEN KANAL",
		review: "Video-Test",
	},
	app_finder: {
		example: "Marke oder Modell...",
		popular: {
			title: "Entdecken",
			list: {
				1: {
					title: "Laufschuhe für Damen",
					link: "/laufschuhe/modelle/alle/geschlecht-damen/",
				},
				2: {
					title: "Laufschuhe für Herren",
					link: "/laufschuhe/modelle/alle/geschlecht-herren/",
				},
				3: {
					title: "Trailrunning Schuhe",
					link: "/laufschuhe/modelle/alle/laufuntergrund-fur-trail/",
				},
				4: {
					title: "Trekkingschuhe",
					link: "/trekkingschuhe/modelle/alle/",
				},
				5: {
					title: "Sportuhren",
					link: "/sportuhren/modelle/alle/",
				},
			},
		},
		most_searched: {
			title: "Die meistgesuchten Modelle",
			list: {
				1: {
					title: "Nike Pegasus 40",
					link: "/laufschuhe/nike/pegasus-40/1018038/",
					img: "https://static.runnea.de/images/202305/nike-pegasus-40-laufschuhe-400x400x90xX.jpg?1",
				},
				2: {
					title: "ASICS Gel Nimbus 25",
					link: "/laufschuhe/asics/gel-nimbus-25/1006080/",
					img: "https://static.runnea.de/images/202212/asics-gel-nimbus-25-laufschuhe-400x400x90xX.jpg?2",
				},
				3: {
					title: "Adidas Ultraboost Light 23",
					link: "/laufschuhe/adidas/ultraboost-23/1014718/",
					img: "https://static.runnea.com/images/202302/adidas-ultraboost-23-zapatillas-running-400x400x90xX.jpg?1",
				},
				4: {
					title: "New Balance FuelCell SC Elite v3",
					link: "/laufschuhe/new-balance/fuelcell-sc-elite-v3/1014560/",
					img: "https://static.runnea.de/images/202302/new-balance-fuelcell-sc-elite-v3-laufschuhe-400x400x90xX.jpg?3",
				},
				5: {
					title: "Garmin Fenix 7",
					link: "/sportuhren/garmin/fenix-7/1005867/",
					img: "https://static.runnea.com/images/202201/garmin-fenix7-ficha-caracteristicas-precios-200x200x80xX.jpg?1",
				},
			},
		},
	},
	consent_cookie: {
		consent_modal: {
			title: "Wir verwenden Cookies, um Ihnen die bestmögliche Erfahrung zu bieten",
			description:
				'Wir verwenden unsere eigenen Cookies, um das grundlegende Funktionieren der Website zu gewährleisten, und Cookies von Drittanbietern, um statistische Daten über Ihr Surfverhalten zu erhalten und um Werbung für personalisierte Produkte zu machen.  Weitere Informationen finden Sie in unserem <a href="/privatsphare.html">Cookies-politik</a>, unsere <a href="/impressum-agb.html">Impressum / AGB</a> und unsere <a href="/datenschutz.html">Datenschutzpolitik</a>.',
			primary_btn: "Alle akzeptieren",
			secondary_btn_1: "Cookies verwalten",
			secondary_btn_2: "Alle ablehnen",
		},
		settings_modal: {
			title: "Cookies verwalten",
			save_settings_btn: "Konfiguration speichern",
			accept_all_btn: "Alle akzeptieren",
			reject_all_btn: "Alle ablehnen",
			close_btn_label: "Schließen",
			cookie_table_headers: {
				col1: "Name",
				col2: "Domain",
				col3: "Zeit",
				col4: "Beschreibung",
			},
			blocks_1: {
				title: "Verwendung von Cookies",
				description:
					'Wir verwenden eigene Cookies, um den grundlegenden Betrieb zu gewährleisten und die beste Erfahrung zu bieten, und Cookies von Drittanbietern, um statistische Daten über Ihr Surfverhalten zu erhalten und personalisierte Produkte anzukündigen. Weitere Informationen finden Sie in unserem <a href="/privatsphare.html">Cookies-politik</a>, unsere <a href="/impressum-agb.html">Impressum / AGB</a> und unsere <a href="/datenschutz.html">Datenschutzpolitik</a>.',
			},
			blocks_2: {
				title: "Unbedingt notwendige Cookies",
				description:
					"Diese Cookies sind für das ordnungsgemäße Funktionieren der Website unerlässlich. Ohne diese Cookies würde die Website nicht ordnungsgemäß funktionieren.",
			},
			blocks_3: {
				title: "Funktionale Cookies",
				description:
					"Diese Cookies sind wichtig für das reibungslose Funktionieren der Website. Ohne diese Cookies können wir Ihnen nicht die beste Erfahrung bieten.",
			},
			blocks_4: {
				title: "Cookies für Analyse und Leistung",
				description: "Diese Cookies ermöglichen es uns, die Nutzung und Leistung der Website anonym zu messen.",
			},
			blocks_5: {
				title: "Werbe- und Marketing-Cookies",
				description: "Diese Cookies ermöglichen es uns, dem Nutzer personalisierte Produkte anzukündigen.",
			},
			cookies: {
				hash: {
					expiration: "Sitzung",
					description:
						"Speichert die Informationen der Seite, von der aus der Anmelde- und Registrierungsprozess bei der Verwendung der Verbindung mit sozialen Netzwerken (Google oder Facebook) durchgeführt wird.",
				},
				auth_strategy: {
					expiration: "1 Monat",
					description: "Speichert die Informationen des vom Nutzer gewählten Anmeldesystems (Local, Google oder Facebook).",
				},
				cc_runnea: {
					expiration: "1 Jahr",
					description: "Speichert Informationen über die Cookie-Einstellungen des Benutzers",
				},
				brander: {
					expiration: "Zwischen 1 Tag und 1 Jahr",
					description: "Speichert die Informationen über die Anzeige von Informationsebenen, damit sie nicht erneut angezeigt werden.",
				},
				ga: {
					expiration: "2 Jahre",
					description:
						"Diese Cookies werden von Google Analytics verwendet, um zwischen eindeutigen Nutzern zu unterscheiden, indem ihnen eine zufällig generierte Nummer zugewiesen wird",
				},
				gid: {
					expiration: "2 Jahre",
					description:
						"Diese Cookies werden von Google Analytics verwendet, um eine eindeutige Nutzer-ID zu generieren, um zu zählen, wie oft ein Nutzer die Website besucht, sowie das Datum des ersten und des letzten Besuchs.",
				},
				fbp: {
					expiration: "3 Monate",
					description:
						"Wird von Facebook verwendet, um eine Reihe von Werbeprodukten bereitzustellen, wie z. B. Echtzeitangebote von externen Werbetreibenden.",
				},
				gads: {
					expiration: "13 Monate",
					description:
						"Sie ist mit dem Dienst DoubleClick for Publishers von Google verbunden und dient dazu, die Interaktionen mit Anzeigen zu messen, um zu verhindern, dass diese wiederholt angezeigt werden.",
				},
			},
		},
	},
	recommender: {
		seo: {
			title: "Schuhberater: Ihr Assistent bei der Wahl des richtigen Schuhs | Shoe finder | RUNNEA",
			description:
				"Eine Schritt-für-Schritt-Anleitung, um den richtigen Laufschuh, Trailrunning-Schuh, Trekking-Schuh oder Sneaker zu finden.",
		},
		tabs: {
			start: {
				text: "Hallo Runnea-Läufer, sind Sie auf der Suche nach einem neuen Paar Laufschuhe? Wir helfen Ihnen in nur wenigen Schritten, den richtigen Schuh für Sie und Ihr Budget zu finden.",
				button: "Starten",
				results: "Preise ergebnisse",
				autor: "Gorka Cabañas",
				specialist: "Experte für Laufschuhe",
			},
			purpose: {
				title: "Ich suche Schuhe für...",
				text: "Laufen auf Asphalt ist nicht dasselbe wie Laufen in den Bergen. Auch ist ein Wanderschuh nicht dasselbe wie ein trendiger Modeschuh. Sagen Sie uns, welche Art von Schuhen Sie suchen.",
				options: {
					running: "Asphalt",
					trail: "Trail",
				},
			},
			gender: {
				title: "Welche Art von Schuhen brauchen Sie?",
				text: "Bei der Suche nach einem passenden Laufschuh müssen wir mit diesen zwei Hauptoptionen beginnen.",
				options: {
					man: "Herren",
					woman: "Damen",
				},
			},
			size: {
				title: "Nennen Sie uns Ihre Schuhgröße",
				text: "RUNNEA-Tipp: Das RUNNEA-Podologen-Team empfiehlt, Sportschuhe eine halbe Nummer größer zu wählen als die, die Sie normalerweise tragen.",
				options: {
					default: "Grösse auswählen",
				},
			},
			footprint: {
				title: "Schrittart",
				text: "Wenn Sie Ihre Schrittart nicht kennen, empfehlen wir Ihnen, eine Schrittanalyse durchzuführen. Sie wird Ihnen helfen, Verletzungen zu vermeiden und sicherer zu laufen.",
				options: {
					pronator: "Uberpronation",
					neutral: "Neutral",
					supinator: "Unterpronation",
					none: "Ich weiss es nicht",
				},
			},
			constitution: {
				title: "Mein Körperbau ist...",
				text: "Unser Körperbau hat einen entscheidenden Einfluss auf die Wahl des richtigen Schuhs. Je schwerer wir sind, desto mehr Dämpfung brauchen wir.",
				options: {
					light: "Leicht",
					medium: "Mittle",
					strong: "Schwer",
					none: "Ich weiss es nicht",
				},
			},
			cushioning: {
				title: "Welche Art von Dämpfung suchen Sie?",
				text: "Bevorzugen Sie eine festere Dämpfung, die Ihnen dabei hilft, Ihre Zeiten zu verbessern, oder suchen Sie Komfort und maximale Dämpfung auf Kosten der Reaktionsfähigkeit?",
				options: {
					soft: "Schnell und reaktiv",
					neutral: "Mittel",
					high: "Maximale dä mpfung",
					none: "Ich weiss es nicht",
				},
			},
			foot: {
				title: "Was ist Ihr Fußtyp?",
				text: "Die Kenntnis Ihres Fußtyps hilft uns bei der Auswahl eines Schuhs, der für Ihren Laufstil am besten geeignet ist.",
				options: {
					normal: "Normaler",
					dig: "Hohlfuß",
					flat: "Plattfuß",
					none: "Ich weiss es nicht",
				},
			},
			use: {
				title: "Wofür werden Sie die Schuhe verwenden?",
				text: "Idealerweise sollten Sie ein Paar Schuhe für Wettkämpfe und ein anderes Paar für das Training haben. Sie haben aber auch die Möglichkeit, einen Hybrid-Schuh zu wählen, den Sie sowohl für das Training als auch für Wettkämpfe verwenden können.",
				options: {
					training: "Trainings",
					competition: "Wettkamp",
					mixed: "Hybrid",
				},
			},
			distance: {
				title: "Für welche Distanzen wollen Sie trainieren?",
				text: "Niemand hier wird darüber urteilen, wie viele Kilometer Sie laufen, aber wenn wir schon dabei sind, sind Sie eher ein Kurz- oder Langstreckenläufer?",
				options: {
					short: "Kurzdistanz",
					"10K": "10K",
					"half-maraton": "Halbmarathon",
					maraton: "Marathon",
					"ultra-trail": "Ultra trail",
				},
			},
			beat: {
				title: "In welchem Tempo laufen Sie normalerweise?",
				text: "Gehören Sie zu denjenigen, die beim Laufen nicht auf die Uhr schauen, oder trainieren Sie jeden Tag, um Ihre Leistung zu verbessern?",
				options: {
					high: "Unter 4:30 Min/Km",
					medium: "Zwischen 4:30 und 5:30 Min/Km",
					low: "Über 5:30 Min/Km",
					none: "Ich weiss es nicht",
				},
			},
			brand: {
				title: "Haben Sie eine Lieblingsmarke?",
				text: "Haben Sie eine enge Beziehung zu Ihrer Lieblingsmarke oder sind Sie offen für neue Marken?",
				options: {
					default: "Marke auswählen",
					none: "Überrasche mich!",
				},
			},
			price: {
				title: "Wie viel Geld wollen Sie ausgeben?",
				text: "Das Budget ist wichtig, aber bei RUNNEA wollen wir Ihnen helfen, den idealen Schuh zum besten Preis zu finden.",
				options: {
					low: "Unter 60€",
					medium: "Unter 100€",
					high: "Über 100€",
					none: "Kein budget",
				},
			},
			result: {
				texts: {
					loading: "Wir suchen nach den Schuhen, die am besten zu den Eigenschaften passen, die Sie gerade konfiguriert haben...",
					contents: "Die besten Schuhe für Sie sind...",
					void: "Wir haben keine Schuhe mit den von Ihnen angegebenen Eigenschaften gefunden. Versuchen Sie es mit unserer Suchfunktion für Laufschuhe:",
					more_results: "Hier kannst du weitere Ergebnisse sehen",
				},
				product: {
					index: "Siehe Eigenschaften",
					prices: "Preise anzeigen",
				},
				buttons: {
					contents: "Siehe andere Optionen",
					void: "Weitere optionen anzeigen",
				},
			},
		},
	},
	runningMusic: {
		imgCalienta: "https://static.runnea.com/images/202309/calienta.jpg?1",
		imgInfoIntencidad: "https://static.runnea.com/images/202309/info-intensidad.png?1",
		imgIntencidad: "https://static.runnea.com/images/202309/intensidad.png?1",
		imgPop: "https://static.runnea.com/images/202309/pop.png?1",
		imgRock: "https://static.runnea.com/images/202309/rock.png?1",
		imgSession01: "https://static.runnea.com/images/202309/sesion-01.png?1",
		imgSession02: "https://static.runnea.com/images/202309/sesion-02.png?1",
		imgSession03: "https://static.runnea.com/images/202309/sesion-03.png?1",
		imgTechno: "https://static.runnea.com/images/202309/techno.png?1",
		imgUrban: "https://static.runnea.com/images/202309/urban.png?1",
	},
	recomend: {
		title: "Recomendador de Zapatillas",
		subTitle: "Respuesta inmediata",
		descriptions: "Te guía con pasos sencillos para escoger tu zapatilla",
	},
};
